<template>
    <div ref="tabOC">
        <v-tabs 
            v-model="tab" 
            class="global-tabs" 
            show-arrows 
            :touchless="true" 
            center-active
            background-color="transparent">
            <v-tab 
                class="tab-oc-global global-tab" 
                v-for="item in tabsItems" 
                :key="item.ConvertInvalidJsxElement"
                :to="item.href">
                {{item.name}}
            </v-tab>
            <v-tabs-slider class="divider-tabs"></v-tabs-slider>
        </v-tabs>
    </div>
</template>
<script>
export default {
    data: () => ({
        tabsItems: [
            {
                name: 'Por pagar',
                href: '/home/payables_and_recievables/payables'
            },
            {
                name: 'Por cobrar',
                href: '/home/payables_and_recievables/recievables'
            }
        ],
        tab: 0
    })
}
</script>